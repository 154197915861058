import {ReactNode} from "react";

import System from "components/system";
import Footer from "components/footer";
import StayInTouch from "./stay-in-touch";
import Popup from "./popup";

interface Props {
  title: string;
  description: string;
  children: ReactNode;
  showStayInTouch?: boolean;
}

export default function Marketing({
  title,
  description,
  showStayInTouch = true,
  children,
}: Props) {
  return (
    <System title={title} content={description}>
      {children}
      {showStayInTouch && <StayInTouch />}
      <Footer />
      <Popup />
    </System>
  );
}
