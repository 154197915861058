import s from "styled-components";
import {ChangeEventHandler, FormEventHandler, useRef, useState} from "react";

import {Section} from "./shared";
import {Spinner} from "bits/Spinner";
import {CheckSmall} from "components/icons";

export type FormSubmissionHandler = (
  value: string
) => Promise<{type: "success" | "error"; message: string}>;

interface FormProps {
  formId?: string;
  title: string;
  titleColor: string;
  description: string;
  descriptionColor: string;
  descriptionMaxWidth: string;
  helpText?: string;
  helpTextColor?: string;
  background: string;

  inputPlaceholder: string;
  inputType: string;

  onSubmit: FormSubmissionHandler;
}

export default function MarketingForm(props: FormProps) {
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const [status, setStatus] = useState<"error" | "success">();
  const [message, setMessage] = useState<string>();

  const onSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    if (value.trim() === "") {
      setValue("");
      input?.current?.focus();
      return;
    }

    setProcessing(true);

    const {type, message} = await props.onSubmit(value);

    if (type === "success") {
      // success
      setStatus("success");
      setMessage(message);
    } else {
      setStatus("error");
      setMessage(message);
    }

    setProcessing(false);
  };

  const valueUpdated: ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value);
    setStatus(undefined);
    setMessage(undefined);
  };

  return (
    <Section
      title={props.title}
      titleColor={props.titleColor}
      description={props.description}
      descriptionColor={props.descriptionColor}
      descriptionMaxWidth={props.descriptionMaxWidth}
      background={props.background}
    >
      <Form id={props.formId} onSubmit={onSubmit}>
        <InlineField>
          <Input
            id="stay-in-touch-email"
            ref={input}
            required
            type={props.inputType}
            placeholder={props.inputPlaceholder}
            value={value}
            onChange={valueUpdated}
            disabled={isProcessing || status === "success"}
          />
          <Button
            disabled={isProcessing || status === "success"}
            type="submit"
            isError={status === "error"}
          >
            {isProcessing ? (
              <Spinner color="#00D515" size={22} />
            ) : status === "success" ? (
              <CheckSmall color="var(--color-primary)" />
            ) : (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.2C0.558172 5.2 0.2 5.55817 0.2 6C0.2 6.44183 0.558172 6.8 1 6.8V5.2ZM13.5657 6.56569C13.8781 6.25327 13.8781 5.74673 13.5657 5.43431L8.47452 0.343146C8.1621 0.0307264 7.65557 0.0307264 7.34315 0.343146C7.03073 0.655565 7.03073 1.1621 7.34315 1.47452L11.8686 6L7.34315 10.5255C7.03073 10.8379 7.03073 11.3444 7.34315 11.6569C7.65557 11.9693 8.1621 11.9693 8.47452 11.6569L13.5657 6.56569ZM1 6.8H13V5.2H1V6.8Z"
                  fill="white"
                />
              </svg>
            )}
          </Button>
        </InlineField>
        {message && <StatusMessage status={status}>{message}</StatusMessage>}
        {props.helpText && (
          <HelpText textColor={props.helpTextColor}>{props.helpText}</HelpText>
        )}
      </Form>
    </Section>
  );
}

const Form = s.form`
display: flex;
flex-direction: column;
align-items: center;
margin: -10px 0 0;

@media (max-width: 550px) {
  margin-top: 20px;
}
`;

const InlineField = s.div`
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
padding: 6px 6px 6px 10px;
width: 280px;
box-sizing: border-box;

border-radius: 9999px;
background: white;
`;

const StatusMessage = s.p<{status: "error" | "success" | undefined}>`
margin-bottom: -10px;

text-align: center;
color: ${props =>
  props.status === "error"
    ? "var(--color-error)"
    : props.status === "success"
    ? "var(--color-primary)"
    : "white"};
${props => props.theme.text.build("nunito", "medium", "bold")};
`;

const Input = s.input`
display: block;
width: 100%;
margin: 0;
padding: 3px 10px;
box-sizing: border-box;

font: inherit;
border: none;
outline: none;
border-radius: 9999px;

${props => props.theme.text.build("nunito", "medium")};
`;

const Button = s.button<{isError: boolean}>`
flex-shrink: 0;
display: flex;
justify-content: center;
align-items: center;
margin: 0;
padding: 0;
width: 30px;
height: 30px;
box-sizing: border-box;

border: none;
font: inherit;
border-radius: 100%;
background: ${props =>
  props.disabled
    ? "white"
    : props.isError
    ? "linear-gradient(to bottom, #FF0000 0%, #D50000 100%)"
    : "linear-gradient(to bottom, #00FF19 0%, #00D515 100%)"};
outline: none;

&:not([disabled]) {
  cursor: pointer;
}

&:not([disabled]):active {
  box-shadow: inset 0 2px 4px rgb(0 0 0 / 10%);
}
`;

const HelpText = s.p<{textColor?: string}>`
max-width: 400px;
margin: 20px 0 0;

text-align: center;
color: ${props => props.textColor};
${props => props.theme.text.build("nunito", "s", "normal")};
font-style: italic;

@media (max-width: 440px) {
  margin-left: 30px;
  margin-right: 30px;
}
`;
